<template>
	<div class="leads-content">
		<loading :active.sync="downloadingLeads"
				 :is-full-page='true'
				 color="#9ba0a5"
				 :enforce-focus="false"/>
		<LeadsTable ref="table" :name="name" :fields="fields" :action="action" :deleteAction="deleteAction">
			<template #more_actions>
				<button class="btn btn-outline-secondary"
						v-b-modal.bulkUpload>Bulk Upload Leads
				</button>
				<button class="btn btn-outline-secondary btn-blue"
						@click="downloadLeads">
					<i class="icon-download"></i>
					Download Leads in CSV
				</button>
			</template>
			<template #actions="row">
				<a :href="row.item.report_url" target="_blank"><img src="../../../../../shared/assets/images/report-icon.svg"></a>
			</template>

		</LeadsTable>
		<Modal modalId='bulkUpload'
			   :handle-ok="uploadLeads"
			   submit-button-text="Bulk Audit Uploads"
			   title="Bulk Audit"
			   size="s">
			<p class="bolder c-black">
				<a class="blue-link" href="/files/bulkupload.csv" download="">Click here</a> to download the template.
			</p>
			<p class="bolder c-black">Follow the setup instructions mentioned
				<a class="blue-link" href="https://growthrobotics.com/bulk-leads-upload/" target="_blank">here</a>.
			</p>
			<p>
				<b-form-file
						v-model="file"
						placeholder="Choose a file or drop it here..."
						drop-placeholder="Drop file here..."
						accept=".csv"
				></b-form-file>
			</p>
		</Modal>
	</div>
</template>

<script>
    import LeadsTable from "./components/LeadsTable";
    import Modal from "../../../../../shared/components/Modal";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "MyLeads",
        components: {Modal, LeadsTable, Loading},
        data() {
            return {
                downloadingLeads: false,
                name: 'leads',
                file: null,
                action: "leads/getLeads",
                deleteAction: "leads/deleteLeads",
                fields: [
                    {
                        key: "check",
                        label: "",
                        sortable: false,
                        tdClass: 'col-sm-1 col-1',
                        thClass: 'col-sm-1 col-1',

                    },
                    {
                        key: "first_name",
                        label: "User Name",
                        sortable: true,
                        tdClass: 'col-sm-2 col-2',
                        thClass: 'col-sm-2 col-2',

                    },
                    {
                        key: "email",
                        label: "User Email",
                        sortable: true,
                        tdClass: 'col-sm-2 col-2',
                        thClass: 'col-sm-2 col-2',

                    },
                    {
                        key: "website_url",
                        label: "Website",
                        sortable: true,
                        tdClass: 'col-sm-2 col-2 text-wrap text-break',
                        thClass: 'col-sm-2 col-2 text-wrap text-break',

                    },
                    {
                        key: "phone",
                        label: "Phone",
                        sortable: true,
                        tdClass: 'col-sm-2 col-2',
                        thClass: 'col-sm-2 col-2',

                    },
                    {
                        key: "created_date",
                        label: "Last Searched",
                        sortable: true,
                        tdClass: 'col-sm-2 col-2',
                        thClass: 'col-sm-2 col-2',

                    },
                    {
                        key: "actions",
                        label: "Report",
                        sortable: false,
                        tdClass: 'col-sm-1 col-1 text-center',
                        thClass: 'col-sm-1 col-1',

                    }
                ]
            }
        },
		methods: {
            downloadLeads() {
                this.downloadingLeads = true;
                return this.$store.dispatch('leads/downloadLeads').then(() => {
                    this.showNotifications({message: 'Leads Successfully Downloaded', type: 'success'});
                    this.downloadingLeads = false;
                }).catch((err) => {
                    this.showErrorNotifications(err);
                    this.downloadingLeads = false;
                })
            },

            uploadLeads( bvModalEvt) {
                bvModalEvt.preventDefault()
                let formData = new FormData();
                formData.append('file', this.file);
                this.$store.dispatch('leads/uploadLeads', formData)
				.then(() => {
                    this.showNotifications({message: 'Leads Successfully Uploaded', type: 'success'});
                    this.$refs.table.refresh();
                    this.file = null;
                    this.$bvModal.hide('bulkUpload');
                }).catch((err) => {
                    this.showErrorNotifications(err);
                })

            }
        }

    }
</script>
