<template>
	<section class="account-cnt">
		<div class="container">
			<div class="row">
				<div class="col-md-4">
					<div class="settings-item">
						<div class="settings-item__img"><img src="../../../../../shared/assets/styles/images/person.svg" alt="">
						</div>
						<h3 class="settings-item__title">Account</h3>
						<validation-observer ref="observer">
							<b-form class="settings-item__form" @submit.prevent="saveAccountInfo">
								<ValidationProvider rules="required" name="Full Name">
									<b-form-group label-for="firstName" label="Full Name"
												  slot-scope="{ errors }">
										<b-form-input
												id="firstName"
												label="Full Name"
												type="text"
												placeholder="Name Surname"
												v-model="firstName"
												:state="errors[0] ? false : null">

										</b-form-input>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<ValidationProvider rules="required|email" name="Email" vid="email">
									<b-form-group label-for="email" label="Email"
												  slot-scope="{ errors }">
										<b-form-input
												id="email"
												type="email"
												placeholder="email@email.com"
												v-model="email"
												:state="errors[0] ? false : null">
										</b-form-input>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<b-form-group label-for="phone" label="Contact Number">
									<b-form-input
											id="phone"
											type="text"
											placeholder="+___________"
											inputmode="text"
											v-model="phone">
									</b-form-input>
								</b-form-group>

								<div class="form-group">
									<label>API Key <i class="svg-image-question-icon"></i></label>
									<input class="form-control form-control--value" type="text"
										   :value="apiKey" readonly="">
								</div>


								<button class="btn btn-secondary" type="submit">Save</button>
							</b-form>
						</validation-observer>
					</div>
				</div>


				<div class="col-md-4">
					<div class="settings-item">
						<div class="settings-item__img"><img src="../../../../../shared/assets/styles/images/lock.svg" alt=""></div>
						<h3 class="settings-item__title">Change Password</h3>

						<validation-observer ref="passwordForm">
							<b-form class="settings-item__form" @submit.prevent="changePassword">
								<ValidationProvider rules="required" name="Current Password" vid="current_password">
									<b-form-group label-for="currentPass" label="Current Password"
												  slot-scope="{ errors }">
										<b-form-input
												id="currentPass"
												type="password"
												v-model="currentPassword"
												class="personal-pass"
												:state="errors[0] ? false : null">
										</b-form-input>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<ValidationProvider rules="required|min:6|max:18" name="New Password" vid="new_password">
									<b-form-group label-for="newPass" label="New Password"
												  slot-scope="{ errors }">
										<b-form-input
												id="newPass"
												type="password"
												class="personal-pass"
												v-model="newPassword"
												:state="errors[0] ? false : null">
										</b-form-input>
										<b-form-invalid-feedback>
											{{ errors[0] }}
										</b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
								<button class="btn btn-secondary" type="submit">Save</button>
							</b-form>
						</validation-observer>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
    import { setInteractionMode } from 'vee-validate'
    setInteractionMode('passive')

    export default {
        name: "Account",
        data() {
            return {
                firstName: '',
                email: '',
                apiKey: '',
                phone: '',
                user: null,
                newPassword: '',
                currentPassword: ''
            }
        },
        mounted() {
            this.$store.dispatch('auth/me')
                .then((response) => {
                    this.firstName = response.user.first_name;
                    this.email = response.user.email;
                    this.apiKey = response.user.api_key;
                    this.phone = response.user.phone;
                    this.user = response.user;
                })
                .catch((error) => {
                    this.showErrorNotifications(error);
                })
        },
        methods: {
            saveAccountInfo() {
                let sendData = {
                    first_name: this.firstName,
                    email: this.email,
                    phone: this.phone,
                };
                if (sendData.first_name !== this.user.first_name || sendData.email !== this.user.email || sendData.phone !== this.user.phone) {

                    this.$refs.observer.validate().then(success => {
                        if (!success) {
                            return;
                        }
                        this.$store.dispatch('auth/updateUserInfo', sendData)
                        .then(() => {
                            this.showNotifications({message: 'Account info successfully updated', type: 'success'});
                        })
                        .catch((err) => {
                            if (!err.response) {
                                this.showNotifications({message: 'Something went wrong!', type: 'danger'})
                            } else if (err.response.status === 422) {
                                this.$refs.observer.setErrors(err.response.data.errors);
                            } else {
                                this.showErrorNotifications(err);
                            }
                        })
                    })
                }

            },
            changePassword() {
                this.$refs.passwordForm.validate().then(success => {
                    if (!success) {
                        return;
                    }
                    let sendData = {
                        current_password: this.currentPassword,
                        new_password: this.newPassword,
                    };
                    this.$store.dispatch('auth/updateUserPassword', sendData)
                        .then(() => {
                            this.showNotifications({message: 'Password successfully updated', type: 'success'});
                        })
                        .catch((err) => {
                            if (!err.response) {
                                this.showNotifications({message: 'Something went wrong!', type: 'danger'})
                            } else if (err.response.status === 422) {
                                this.$refs.passwordForm.setErrors(err.response.data.errors);
                            } else {
                                this.showErrorNotifications(err);
                            }
                        })

                })
            },
        }
    }
</script>
